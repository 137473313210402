import React from 'react';
import { Routes, Route } from 'react-router-dom';

//components




//pages

import Home from '../Home';

const App = () => {

  return (
    <>
      <main>
        <section className='contentWrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
          </Routes>
        </section>
      </main>
    </>
  );
};

export default App;
