import React from 'react';
import BadamLogo from '../../images/Logo.png';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdCall } from 'react-icons/md';
import { ImLocation } from 'react-icons/im';
import {
  AiFillMail,
  AiFillInstagram,
  AiFillTwitterCircle,
} from 'react-icons/ai';
import { FaFacebook } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <div className='footer'>
        <Container>
          <Row>
            <Col md={4}>
              <div>
                <img src={BadamLogo} alt='' />
                <p className='footer__desc'>
                  Delightful drinks and refreshments are always worth your time.
                  With the growing consumer demand for sustainable offerings and
                  simplicity, we bring you the real ingredients used within
                  dairy and beverage products.{' '}
                </p>
              </div>
            </Col>
            <Col md={3}>
              <p className='footer__secondheading'>Quick Links</p>
              <ul className='footer__listing'>
                <li>
                  <Link to=''>About Us </Link>
                </li>
                <li>
                  <Link to=''>Terms & Conditions </Link>
                </li>
                <li>
                  <Link to=''>Privacy & Policy </Link>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <p>Contact Us</p>
              <ul>
                <li>
                  <AiFillMail className='me-3' /> mahabaleshwor@gmail.com
                </li>
                <li>
                  <ImLocation className='me-3' />
                  Belbariya, Butwal, Nepal
                </li>
                <li>
                  <MdCall className='me-3' />
                  9857025489
                </li>
              </ul>
            </Col>
            <Col md={2}>
              <p>Follow us on </p>
              <a
                href={
                  'https://www.facebook.com/Mahabaleshwor-Dairy-and-Beverage-Pvt-Ltd-108144248233170'
                }
                target='_blank'
                rel='noreferrer noopener'
              >
                <FaFacebook className='footer__icon' />{' '}
              </a>
              <a
                href={
                  'https://www.facebook.com/Mahabaleshwor-Dairy-and-Beverage-Pvt-Ltd-108144248233170'
                }
                target='_blank'
                rel='noreferrer noopener'
              >
                <AiFillTwitterCircle className='footer__icon' />{' '}
              </a>
              <a
                href={
                  'https://www.facebook.com/Mahabaleshwor-Dairy-and-Beverage-Pvt-Ltd-108144248233170'
                }
                target='_blank'
                rel='noreferrer noopener'
              >
                <AiFillInstagram className='footer__icon' />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
