import React, { useState } from 'react';
import { Col, Row, Container, Form, Button, Nav } from 'react-bootstrap';
import NavBar from '../../components/Navbbar';
import HomeBanner from '../../images/homebanner.png';
import Badam from '../../images/product.png';
import BadamDrink from '../../images/product 2.png';
import { FaQuoteLeft } from 'react-icons/fa';
import Footer from '../../components/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiFillMail } from 'react-icons/ai';
import { MdCall } from 'react-icons/md';
import { ImLocation } from 'react-icons/im';

const Home = () => {
  const [name, setName] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`https://sajhamail.herokuapp.com/api/mail/message`, {
        name,
        phonenumber,
        email,
        message,
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    setName('');
    setPhoneNumber('');
    setEmail('');
    setMessage('');
  };
  const products = [
    {
      id: '01',
      name: 'Badam Milk',
      img: Badam,
      para1:
        'Reva Badam Milk does taste like the real bits of almond and with the flavor of saffron,  which is a refreshing change of pace. Our mission is simple: to make outstanding beverages for you, and to benefit the greater good. For the affordability and the real almond taste, Reva badam milk gets a thumbs up. So, don’t compromise on the quality of the unbelievable deliciousness! ',
    },
    {
      id: '02',
      name: 'Badam Juice',
      img: BadamDrink,
      para1:
        ' With a perfectly balanced texture and amazing taste, the Reva badam drink brings summer joy in a can. It is a bit nutty and sweet and reflects the true, delicious essence of almonds, and it tastes the best when served chilled. The ultimate beverage for energy seekers. Taste the deliciousness!',
    },
  ];
  const testimonials = [
    {
      para: 'Not only the taste, the amount of energy I feel after drinking these badam drinks is the real deal. Highly recommended',
      name: 'Uday Bhandari',
      post: 'Local Customer',
    },
    {
      para: 'My gym mate after every workout',
      name: 'Sagar Giri',
      post: 'Entrepreneur',
    },
    {
      para: 'We use these regularly at home. the taste is awsome ',
      name: 'Arjun Kunwar',
      post: 'Doctor',
    },
  ];
  const settings = {
    infinite: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <NavBar />
      <div className='home'>
        <div className='home__banner'>
          <img src={HomeBanner} alt='' />
        </div>
        <Container>
          <div className='home__bannerContent'>
            <Row>
              <Col md={6}>
                <p className='home__bannerContent--heading'>
                  Enjoy naturally fresh “Badam Milk & Badam Drink” in these
                  blazing hot days.
                </p>
                <p className='home__bannerContent--paragraph'>
                  Experience the real taste and boost your day with the power of
                  almond and milk during summer.
                </p>

                <Nav.Link href='#contact'>Contact Us</Nav.Link>
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <p className='home__productheading'>Our Products</p>

          <div className='home__products'>
            {products.map((curElm) => {
              return (
                <>
                  <Row className='nthselector mb-5'>
                    <Col md={5} className='abcd'>
                      <div>
                        <img src={curElm.img} alt='' />
                      </div>
                    </Col>
                    <Col md={7}>
                      <p className='home__products--id'>{curElm.id}</p>
                      <p className='home__products--name'>{curElm.name}</p>
                      <p
                        className='home__products--desc w-75 mt-4'
                        style={{ lineHeight: '200%' }}
                      >
                        {curElm.para1}
                      </p>
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
        </Container>
        <div className='home__aboutus' id='about'>
          <Container>
            <p className='home__aboutus--heading'>About Us</p>
            {/* <video controls>
              <source
                src="https://www.youtube.com/watch?v=LepyhWgUwMc&list=RD3u_YV8UkhTc&index=27&ab_channel=TigerCreation"
                type="video/mp4"
              />
            </video> */}
            <iframe
              width='100%'
              height='600'
              src='https://www.youtube.com/embed/pEb_fwidR8Q'
              title='Reva badam milk promotional documentary'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </Container>
        </div>
        <Container>
          <div className='home__testimonials'>
            <p className='home__testimonials--heading'>Testimonials</p>

            <Row>
              <Slider {...settings}>
                {testimonials.map((curElm) => {
                  return (
                    <Col md={6} sm={12}>
                      <div className='home__testimonials--content'>
                        <FaQuoteLeft className='home__testimonials--content-quote' />
                        <p className='home__testimonials--content-para'>
                          {curElm.para}
                        </p>
                        <p className='home__testimonials--content-name'>
                          {curElm.name}
                        </p>
                        <p className='home__testimonials--content-post'>
                          {curElm.post}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Slider>
            </Row>
          </div>
        </Container>

        <div className='home__contactus' id='contact'>
          <Container>
            <p className='home__contactus--heading'>Contact Us</p>
            <Row>
              <Col md={6}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter your Fullname'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter your email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter your number'
                      value={phonenumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      placeholder='Type your message here'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button onClick={handleSubmit}>Submit</Button>
                </Form>
              </Col>
              <Col md={6}>
                {/* <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.547404688738!2d83.4570145500519!3d27.66947133367912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996857d751b77c5%3A0xa381dc824bd7487b!2sYogikuti!5e0!3m2!1sen!2snp!4v1655705848771!5m2!1sen!2snp' /> */}
                {/* <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60229.14580810854!2d83.41538398647101!3d27.658942603462414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3996858b6cd2c31f%3A0x8f373b8951ee2d7e!2sMotipur!5e0!3m2!1sen!2snp!4v1656395206252!5m2!1sen!2snp'></iframe>{' '} */}
                <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37957.87978668769!2d83.42407896628167!3d27.684421011217292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39968f49909c3737%3A0x905041bebcf7cbbf!2sMahabaleshwor%20Dairy%20and%20Beverage%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1656925733542!5m2!1sen!2snp'></iframe>
              </Col>
            </Row>
          </Container>
        </div>

        <section className='otherContactWrapper'>
          <Container>
            <p className='home__contactus--heading'>Other Contacts</p>{' '}
            <Row>
              <Col md={6} sm={12}>
                <section className='BranchContact'>
                  <section className='BranchContact-location'>
                    <ImLocation className='me-3' />
                    Koshi Province and Madhesh Province{' '}
                  </section>
                  <section className='BranchContact-name'>
                    <span>Deepak Dahal </span>
                    <small className='BranchContact-name__post'>
                      Area Manager{' '}
                    </small>
                  </section>
                  <section className='BranchContact-Contact'>
                    <MdCall className='me-3' />
                    9857083049
                  </section>
                </section>
              </Col>
              <Col md={6} sm={12}>
                <section className='BranchContact'>
                  <section className='BranchContact-location'>
                    <ImLocation className='me-3' />
                    Bagmati Province{' '}
                  </section>
                  <section className='BranchContact-name'>
                    <span>Dipesh Lamsal </span>
                    <small className='BranchContact-name__post'>
                      Area Manager{' '}
                    </small>
                  </section>
                  <section className='BranchContact-Contact'>
                    <MdCall className='me-3' />
                    9857083051
                  </section>
                </section>
              </Col>
              <Col md={6} sm={12}>
                <section className='BranchContact'>
                  <section className='BranchContact-location'>
                    <ImLocation className='me-3' />
                    Lumbini Province{' '}
                  </section>
                  <section className='BranchContact-name'>
                    <span>Gyan Bahadur Thapa </span>
                    <small className='BranchContact-name__post'>
                      Area Manager{' '}
                    </small>
                  </section>
                  <section className='BranchContact-Contact'>
                    <MdCall className='me-3' />
                    9857083052
                  </section>
                </section>
              </Col>
              <Col md={6} sm={12}>
                <section className='BranchContact'>
                  <section className='BranchContact-location'>
                    <ImLocation className='me-3' />
                    Gandaki, Karnali and Sudurpaschim Province{' '}
                  </section>
                  <section className='BranchContact-name'>
                    <span>Toyanath Sharma </span>
                    <small className='BranchContact-name__post'>
                      Area Manager{' '}
                    </small>
                  </section>
                  <section className='BranchContact-Contact'>
                    <MdCall className='me-3' />
                    9857083050
                  </section>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;
